import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { map } from 'rxjs';
import { PharmaciesService } from '@services/pharmacies.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  endpoint: string = 'orders';
	params: HttpParams = new HttpParams();
	user: any;
	roleUser: string[] = [];
	respuesta: any;
	orders: any[] = [];
	pharmacies: []= [];
	search: string = '';
	search$ = new EventEmitter<string>();

	filter$ = new EventEmitter<any>();

  constructor(
    private http: HttpClient,
    private _pharmacies: PharmaciesService
  ) { }

  getPharmacies() {
		this._pharmacies.getPharmacies().subscribe((res) => this.pharmacies = res);
	}

  getOrdenes() {
		this.getPharmacies(); 
		return this.http.get(`${environment.apiUrl}/${this.endpoint}`, {
		params: new HttpParams()
			.set('page', '1')
			.set('size', '5000')
		}).pipe(
			map((res:any) => {
				const response: any[] = [];
				const pharmacies: any[] = []; 
				this.pharmacies.map((farmacia) => {
					const { id, name } = farmacia
					pharmacies.push({ id, name });
				});

				const { result } = res;
				pharmacies.forEach((r, i) => {
					const orders = this.searchOrdersByPharmacyAndDate(r.id, result);					
					const currentPharma = pharmacies[i];
					response.push({ ...currentPharma, ...orders });
				});
				
				return { header: res.header, pharmacies: response };
			})
		);
	}

  searchOrdersByPharmacyAndDate(id: number, orders: []) {
		let validate =  0;
		let problemInfoUser = 0;
		let needUser = 0;
		let packOff = 0;
		
		orders.forEach((order) => {
			const { pharmacy: { id: orderId }, status } = order;
			if (orderId === id) {
				switch (status) {
					case 'VALIDATE':
						validate += 1;            
						break;
					case 'PROBLEM_INFO_USER':
						problemInfoUser += 1;            
						break;
					case 'WAITING_FOR_PAYMENT':
						needUser += 1;            
						break;
					case 'PACK_OFF':
						packOff += 1;            
						break;
					default:
						break;
				}
			}
		});

		return { validate, problemInfoUser, packOff, needUser };
	}
  
}
