export class SetFilteredOrdersAction {
    static readonly type = '[Filters] Set Filtered Orders';
    constructor(public status: boolean){}
}
export class SetFilteredRequestsAction {
    static readonly type = '[Filters] Set Filtered Requests';
    constructor(public status: boolean){}
}
export class SetFilteredClientsAction {
    static readonly type = '[Filters] Set Filtered Clients';
    constructor(public status: boolean){}
}
export class SetFiltersOrdersAction {
    static readonly type = '[Filters] Set Filter Order';
    constructor(public filters: OrdersParams) { }
}

export class CleanFiltersOrdersAction {
    static readonly type = '[Filters] Clean Filter Order'
}

export class SetFiltersRequestsAction {
    static readonly type = '[Filters] Set Filter Requests';
    constructor(public filters: any) { }
}

export class CleanFiltersRequestsAction {
    static readonly type = '[Filters] Clean Filter Requests'
}
export class CleanSearchRequestsAction {
    static readonly type = '[Filters] Clean Search Requests'
}

export class SetFiltersClientsAction {
    static readonly type = '[Filters] Set Filter Clients';
    constructor(public filters: any) { }
}

export class CleanFiltersClientsAction {
    static readonly type = '[Filters] Clean Filter Clients'
}

export class SetFiltersChatsAction {
    static readonly type = '[Filters] Set Filter Chats';
    constructor(public filters: any) { }
}

export class CleanFiltersChatsAction {
    static readonly type = '[Filters] Clean Filter Chats'
}

export class SetFiltersMedicinesAction {
    static readonly type = '[Filters] Set Filter Medicines';
    constructor(public filters: any) { }
}
export class CleanFiltersMedicinesAction {
    static readonly type = '[Filters] Clean Filter Medicines'
}

export class GetDateOrderFilterAction {
    static readonly type = '[Filters] Get Date Filter'
}

export interface OrdersParams {
    status?           : string
    recipeStatus?     : string
    order_id?         : string
    dni?              : string
    page?             : string | number
    from?             : string
    to?               : string
    name?             : string
    user_name?        : string
    size?             : string
    pharmacy_id?      : string
    province?         : string
    treatment_id?     : string
    sub_status?       : string
    sort?             : string
  }
