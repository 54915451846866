import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { 
    GetMedicineAction,
    GetMedicineHeadersAction, 
    SetSelectedMedicine,
    GetMedicinesAction,
    GetMedicineTipos,
    GetMedicineTratamientos,
    GetMedicineDefiniciones
} from './medicines.actions';
//import { OrdersService } from '@orders/services/orders.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { 
  IMedicineHeader, 
  IMedicine, 
  IResultMedicine, 
  IMedicineType,
  IMedicinePresentation,
  IMedicineTreatment 
} from '@interfaces/medicinas.interface';
import { MedicinesService } from '@services/medicines.service';


export class MedicinesStateModel {
  public header: IMedicineHeader;
  public medicinas: IMedicine[];
  public selectedMedicine: IMedicine;
  public medicinasTipos: IMedicineType;
  public medicinasTratamientos: IMedicineTreatment;
  public medicinasDefiniciones: IMedicinePresentation;
}

@State<MedicinesStateModel>({
  name: 'medicines',
  defaults: {
    header: null,
    medicinas: [],
    selectedMedicine: null,
    medicinasTipos: null,
    medicinasTratamientos: null,
    medicinasDefiniciones: null,
  },
})

@Injectable()
export class MedicinesState {
  constructor(
    private readonly _medicinas: MedicinesService,
  ) {}

  @Selector()
  public static getHeader({ header }: MedicinesStateModel): IMedicineHeader {
    return header;
  }


  @Selector()
  public static getMedicineList({ medicinas }: MedicinesStateModel): IMedicine[] {
    return medicinas;
  }

  @Selector()
  public static getSelectedMedicine({ selectedMedicine }): IMedicine {
    return selectedMedicine;
  }

  @Selector()
  public static getMedicineTipos({ medicinasTipos }): IMedicineType {
    return medicinasTipos;
  }

  @Selector()
  public static getMedicineTratamientos({ medicinasTratamientos }): IMedicineTreatment {
    return medicinasTratamientos;
  }

  @Selector()
  public static getMedicineDefiniciones({ medicinasDefiniciones }): IMedicinePresentation {
    return medicinasDefiniciones;
  }


  @Action(GetMedicinesAction)
  getMedicines({ getState, setState }: StateContext<MedicinesStateModel>, { } : GetMedicinesAction ): Observable<IResultMedicine> {
    //return this._orders.getOrders(parametros).pipe(
    return this._medicinas.getMedicines({}).pipe(  
      tap((respuesta: IResultMedicine) => {
        const state = getState();
        setState({ ...state, header: respuesta.header, medicinas: respuesta.result  }); // header: respuesta.header, section: respuesta.section,
      })
    );
  }

  @Action(GetMedicineAction)
  getMedicine({ getState, setState }: StateContext<MedicinesStateModel>, { id } : GetMedicineAction ): Observable<IResultMedicine> {
    //return this._orders.getOrders(parametros).pipe(
    return this._medicinas.getMedicine(id).pipe(  
      tap((respuesta: IResultMedicine) => {
        const state = getState();
        setState({ ...state, header: respuesta.header, selectedMedicine: respuesta.result[0]  }); // header: respuesta.header, section: respuesta.section,
      })
    );
  }

  @Action(SetSelectedMedicine)
  setSelected({ getState, setState }: StateContext<MedicinesStateModel>,{ payload }: SetSelectedMedicine) {
    const state = getState();
    setState({ ...state, selectedMedicine: payload })
  }

  @Action(GetMedicineTipos)
  getTipos({ getState, setState }: StateContext<MedicinesStateModel>, { id } : GetMedicineTipos ): Observable<IMedicineType> {
    return this._medicinas.getMedicineType(id).pipe(
      tap((respuesta: IMedicineType) => {
        const state = getState();
        setState({ ...state,  medicinasTipos: respuesta})
      })
    )
  }

  @Action(GetMedicineTratamientos)
  getTratamientos({ getState, setState }: StateContext<MedicinesStateModel>, {} : GetMedicineTratamientos ): Observable<IMedicineTreatment> {
    return this._medicinas.getMedicineTreatmentType().pipe(
      tap((respuesta: IMedicineTreatment) => {
        const state = getState();
        setState({ ...state,  medicinasTratamientos: respuesta})
      })
    )
  }

  @Action(GetMedicineDefiniciones)
  getDefiniciones({ getState, setState }: StateContext<MedicinesStateModel>, {} : GetMedicineDefiniciones ): Observable<IMedicinePresentation> {
    return this._medicinas.getMedicinePresentation().pipe(
      tap((respuesta: IMedicinePresentation) => {
        const state = getState();
        setState({ ...state,  medicinasDefiniciones: respuesta})
      })
    )
  }

  
  
}