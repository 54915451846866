import { Injectable, inject } from "@angular/core";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { filter, Observable } from "rxjs";
import {
    CleanFiltersChatsAction,
    CleanFiltersClientsAction,
    CleanFiltersMedicinesAction,
    CleanFiltersOrdersAction,
    CleanFiltersRequestsAction,
    OrdersParams,
    SetFiltersChatsAction,
    SetFiltersClientsAction,
    SetFiltersMedicinesAction,
    SetFiltersOrdersAction,
    SetFiltersRequestsAction,
    CleanSearchRequestsAction,
    SetFilteredOrdersAction,
    SetFilteredRequestsAction,
    SetFilteredClientsAction
} from "./filters.actions";

import { OrdersService } from "@orders/orders.service";

export class FiltersStateModel {
    orders: OrdersParams;
    ordersFiltered: boolean;
    requests: any;
    requestsFiltered: boolean;
    clients: any;
    clientsFiltered: boolean;
    chats: any;
    medicines: any;
}

@State<FiltersStateModel>({
    name: 'filters',
    defaults: {
        orders: {
            status              : '',
            recipeStatus        : '',
            order_id            : '',
            dni                 : '',
            from                : '',
            to                  : '',
            name                : '',
            user_name           : '',
            pharmacy_id         : '',
            province            : '',
            treatment_id        : '',
            sub_status          : '',
            sort                : 'ORDER_ID_DESC',
            page                : '1',
            size                : '25'
        },
        ordersFiltered: false,
        requests: {
            id                  : '',
            dni                 : '',
            status              : '',
            page                :'0',
            size                :'25',
            user_name           : '',
            doctor              : '',
            treatment           : '',
        },
        requestsFiltered: false,
        clients: {
            client_id           : '',
            user_name           : '',
            dni                 : '',
            telephone           : '',
            next_delivery_date  : '',
            subscribed          : '',
            page                : '1',
            size                : '25',
            sort                : '',
        },
        clientsFiltered: false,
        chats: {
            status              :'ALL',
            size                :'10',
            page                :'0',
            'name-or-document'  : ''
        },
        medicines: {
            medicine_id         :'',
            brand               :'',
            laboratory          :'',
            type                :'',
            page                :'1',
            size                :'10',
            sort                :''
        }
    }
})


/*Clients */
/*
 id                  : '',
dni                 : '',
status              : '',
page                : '1',
from                : '',
to                  : '',
customer            : '',
doctor              : '',
size                : '10',
sort                : '',
*/

// Requests:  id=&dni=&status=&page=0&size=10&user_name=&doctor= <-- es el id del doctor
// : ?client_id=&user_name=&dni=&telephone=&next_delivery_date=&subscribed=&page=1&size=10&sort=
// Clients: id=&status=&page=1&from=&to=&customer=&doctor=&size=10&sort=
// Chats: status=ALL&size=10&page=0&name-or-document=
// Medicinas: medicine_id=&brand=&laboratory=&type=&page=1&size=10&sort=

@Injectable()
export class FiltersState {
    private store = inject(Store);
    private _orders = inject(OrdersService);

    @Selector()
    public static getOrdersFilters({ orders }: FiltersStateModel) {
        return orders;
    }
    @Selector()
    public static getDateOrderFilterAction({ orders }: FiltersStateModel) {
        const fecha = {
            from: orders.from,
            to: orders.to
        }
        return fecha;
    }
    @Selector()
    public static getRequestsFilters({ requests }: FiltersStateModel) {
        return requests;
    }
    @Selector()
    public static getClientsFilters({ clients }: FiltersStateModel) {
        return clients;
    }
    @Selector()
    public static getChatsFilters({ chats }: FiltersStateModel) {
        return chats;
    }
    @Selector()
    public static getMedicinesFilters({ medicines }: FiltersStateModel) {
        return medicines;
    }
    @Selector()
    public static getStatusFilteredOrders({ ordersFiltered }: FiltersStateModel) {
        return ordersFiltered;
    }
    @Selector()
    public static getStatusFilteredRequests({ requestsFiltered }: FiltersStateModel) {
        return requestsFiltered;
    }
    @Selector()
    public static getStatusFilteredClients({ clientsFiltered }: FiltersStateModel) {
        return clientsFiltered;
    }

    //******************* Orders
    @Action(SetFiltersOrdersAction)
    setFiltersOrdersAction({ getState, patchState }: StateContext<FiltersStateModel>, { filters } : SetFiltersOrdersAction ) {
        const oldFilters: OrdersParams = getState().orders
        const newFilters: any = {
            status: filters.status ?? oldFilters.status,
            recipeStatus: filters.recipeStatus ?? oldFilters.recipeStatus ?? '',
            order_id: filters.order_id ?? oldFilters.order_id,
            dni: filters.dni ?? oldFilters.dni,
            from: filters.from ?? oldFilters.from,
            to: filters.to ?? oldFilters.to,
            name: filters.name ?? oldFilters.name,
            user_name: filters.user_name ?? oldFilters.user_name,
            pharmacy_id: filters.pharmacy_id ?? oldFilters.pharmacy_id,
            province: filters.province ?? oldFilters.province,
            treatment_id: filters.treatment_id ?? oldFilters.treatment_id,
            sub_status: filters.sub_status ?? oldFilters.sub_status,
            sort: filters.sort ?? oldFilters.sort,
            page: filters.page ?? oldFilters.page,
            size: filters.size ?? oldFilters.size
        }

        patchState({
            orders: newFilters
        })

        // this._orders.filters.update(status => ({...status, ...newFilters}))
        this._orders.filters.set(newFilters)
        //this.store.dispatch(new GetOrdersAction());
    }

    @Action(SetFilteredOrdersAction)
    setFilteredOrdersAction({ getState, patchState }: StateContext<FiltersStateModel>, { status } : SetFilteredOrdersAction ) {
        const state = getState()
        patchState({
            ...state,
            ordersFiltered: status
        })
    }

    @Action(CleanFiltersOrdersAction)
    cleanFilterOrdersAction(ctx: StateContext<FiltersStateModel>) {
        const oldFilters: OrdersParams = ctx.getState().orders
        const orders: OrdersParams = { ...oldFilters, name: '', user_name: '', order_id: '', dni: '', treatment_id: '', sub_status: '' }
        const state = ctx.getState()
        ctx.setState({ ...state, orders, ordersFiltered: false })
        // this.store.dispatch(new GetOrdersAction());
    }

    //**************** Requests
    @Action(SetFiltersRequestsAction)
    setFiltersRequestsAction({ getState, patchState }: StateContext<FiltersStateModel>, { filters } : SetFiltersRequestsAction ) {
        const oldFilters: any = getState().requests
        const newFilters: any = {
            id              : filters.id || oldFilters.id,
            dni             : filters.dni || oldFilters.dni,
            status          : filters.status !== undefined ? filters.status : oldFilters.status,
            page            : filters.page,
            size            : filters.size || oldFilters.size,
            user_name       : filters.user_name || oldFilters.user_name,
            doctor          : filters.doctor !== undefined ? filters.doctor : oldFilters.doctor,
            treatment       : filters.treatment !== undefined ? filters.treatment : oldFilters.treatment,
        }

        const state = getState()
        patchState({
            ...state,
            requests: newFilters
        })
    }

    @Action(SetFilteredRequestsAction)
    setFilteredRequestsAction({ getState, patchState }: StateContext<FiltersStateModel>, { status } : SetFilteredRequestsAction ) {
        const state = getState()
        patchState({
            ...state,
            requestsFiltered: status
        })
    }

    @Action(CleanFiltersRequestsAction)
    cleanFilterRequestsAction(ctx: StateContext<FiltersStateModel>) {
        const oldFilters: any = ctx.getState().requests
        const requests: any = { ...oldFilters, user_name: '', id: '', dni: '', doctor: '', treatment: '' }
        const state = ctx.getState()
        ctx.setState({ ...state, requests, requestsFiltered: false })
    }

    @Action(CleanSearchRequestsAction)
    cleanSearchRequestsAction(ctx: StateContext<FiltersStateModel>) {
        const oldFilters: any = ctx.getState().requests
        const requests: any = { ...oldFilters, user_name: '', id: '', dni: '' }
        const state = ctx.getState()
        ctx.setState({ ...state, requests})
    }

    //***************** Clients
    @Action(SetFiltersClientsAction)
    setFiltersClientsAction({ getState, patchState }: StateContext<FiltersStateModel>, { filters } : SetFiltersClientsAction ) {
        const oldFilters: any = getState().clients
        const newFilters: any = {
            // id                  : filters.id != undefined ? filters.id : oldFilters.id,
            // dni                  : filters.dni != undefined ? filters.dni : oldFilters.dni,
            // status              : filters.status != undefined ? filters.status : oldFilters.status,
            // page                : filters.page != undefined ? filters.page : oldFilters.page,
            // from                : filters.from != undefined ? filters.from : oldFilters.from,
            // to                  : filters.to != undefined ? filters.to : oldFilters.to,
            // customer            : filters.customer != undefined ? filters.customer : oldFilters.customer,
            // doctor              : filters.doctor != undefined ? filters.doctor : oldFilters.doctor,
            // size                : filters.size != undefined ? filters.size : oldFilters.size,
            // sort                : filters.sort != undefined ? filters.sort : oldFilters.sort,
            client_id           : filters.client_id != undefined ? filters.client_id : oldFilters.client_id,
            user_name           : filters.user_name != undefined ? filters.user_name : oldFilters.user_name,
            dni                 : filters.dni != undefined ? filters.dni : oldFilters.dni,
            telephone           : filters.telephone != undefined ? filters.telephone : oldFilters.telephone,
            next_delivery_date  : filters.next_delivery_date != undefined ? filters.next_delivery_date : oldFilters.next_delivery_date,
            subscribed          : filters.subscribed != undefined ? filters.subscribed : oldFilters.subscribed,
            page                : filters.page || oldFilters.page,
            size                : filters.size || oldFilters.size,
            sort                : filters.sort || oldFilters.sort,
        }

        const state = getState()
        patchState({
            ...state,
            clients: newFilters
        })
    }

    @Action(SetFilteredClientsAction)
    setFilteredClientsAction({ getState, patchState }: StateContext<FiltersStateModel>, { status } : SetFilteredClientsAction ) {
        const state = getState()
        patchState({
            ...state,
            clientsFiltered: status
        })
    }

    @Action(CleanFiltersClientsAction)
    cleanFilterClientsAction(ctx: StateContext<FiltersStateModel>) {
        const oldFilters: any = ctx.getState().clients
        const clients: any = { ...oldFilters, user_name: '', client_id: '', doctor: '', dni: '' }
        const state = ctx.getState()
        ctx.setState({ ...state, clients, clientsFiltered: false })
    }

    //****************** Chats
    @Action(SetFiltersChatsAction)
    setFiltersChatsAction({ getState, patchState }: StateContext<FiltersStateModel>, { filters } : SetFiltersChatsAction ) {
        const oldFilters: any = getState().clients
        const newFilters: any = {
            status              : filters.status || oldFilters.status,
            page                : filters.page || oldFilters.page,
            size                : filters.size || oldFilters.size,
            'name-or-document'  : filters['name-or-document'] || oldFilters['name-or-document'],
        }

        const state = getState()
        patchState({
            ...state,
            chats: newFilters
        })
    }

    @Action(CleanFiltersChatsAction)
    cleanFilterChatsAction(ctx: StateContext<FiltersStateModel>) {
        const oldFilters: any = ctx.getState().chats
        const chats: any = { ...oldFilters, status: '', 'name-or-document': '' }
        const state = ctx.getState()
        ctx.setState({ ...state, chats})
    }

    // Medicines
    @Action(SetFiltersMedicinesAction)
    setFiltersMedicinesAction({ getState, patchState }: StateContext<FiltersStateModel>, { filters } : SetFiltersMedicinesAction ) {
        const oldFilters: any = getState().medicines
        const newFilters: any = {
            // filters.status !== undefined ? filters.status : oldFilters.status,
            medicine_id: filters.medicine_id !== undefined ? filters.medicine_id : oldFilters.medicine_id,
            brand: filters.brand !== undefined ? filters.brand : oldFilters.brand,
            laboratory: filters.laboratory !== undefined ? filters.laboratory : oldFilters.laboratory,
            type: filters.type !== undefined ? filters.type : oldFilters.type,
            page: filters.page !== undefined ? filters.page : oldFilters.page,
            size: filters.size !== undefined ? filters.size : oldFilters.size,
            sort: filters.sort !== undefined ? filters.sort : oldFilters.sort,
        }

        const state = getState()
        patchState({
            ...state,
            medicines: newFilters
        })
    }

    @Action(CleanFiltersMedicinesAction)
    cleanFilterMedicinesAction(ctx: StateContext<FiltersStateModel>) {
        const oldFilters: any = ctx.getState().medicines
        const medicines: any = { ...oldFilters, medicine_id: '', brand: '', laboratory: '', type: '' }
        const state = ctx.getState()
        ctx.setState({ ...state, medicines})
    }
}
