
export class GetMedicineHeadersAction {
    static readonly type = '[Medicines] Get Header';
    //constructor(public payload: IHeader) { }
}

export class GetMedicinesAction {
    static readonly type = '[Medicines] Get Medicines';
    //constructor(public parametros: any) { }
}

export class GetMedicineAction {
    static readonly type = '[Medicines] Get Medicine';
    constructor(public id: string) { }
}

export class SetSelectedMedicine {
    static readonly type = '[Medicines] Set Medicine Selected';
    constructor(public payload: any) { }
}

export class GetMedicineTipos {
    static readonly type = '[Medicines] Get Medicine Types';
    constructor(public id: number) {}
}

export class GetMedicineTratamientos {
    static readonly type = '[Medicines] Get Medicine Tratamientos';
}

export class GetMedicineDefiniciones {
    static readonly type = '[Medicines] Get Medicine Definiciones';
}