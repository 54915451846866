import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable, tap } from "rxjs";
import { ChatService } from "@chat/chat.service";
import { ClearSelectedConversation, GetConversationsActions, GetMessagesAction, SetSelectedConversation } from "./chats.actions";


export class ChatStateModel {
    sections: any;
    conversations: any;
    conversation: any;
    selectedConversation: any;
}

@State<ChatStateModel>({
    name: 'chat',
    defaults: {
        sections: null,
        conversations: null,
        conversation: null,
        selectedConversation: null
    }
})

@Injectable()
export class ChatState {
    
    constructor(
        private _cs: ChatService
    ) {}

    @Selector()
    public static getSections({ sections }: ChatStateModel) {
        return sections;
    }

    @Selector()
    public static getMessages({ conversation }: ChatStateModel) {
        return conversation;
    }

    @Selector()
    public static getConversations({ conversations }: ChatStateModel) {
        return conversations;
    }

    @Selector()
    public static getSelectedConversation({ selectedConversation }: ChatStateModel) {
        return selectedConversation
    }

    @Action(GetConversationsActions)
        getConversations(ctx: StateContext<ChatStateModel>, {page, quantity, status, searchFilter}: GetConversationsActions):Observable<any> {
            return this._cs.getConversations(page, quantity, status, searchFilter).pipe(
                tap((conversations: any) => {
                    const state = ctx.getState()
                    ctx.setState({...state, conversations, sections: conversations.sections})
                })
            );
    };
        
    @Action(SetSelectedConversation)
        setSelectedConversation(ctx: StateContext<ChatStateModel>, { selectedConversation }: SetSelectedConversation) {
            const state = ctx.getState()
            ctx.setState({...state, selectedConversation})
    }

    @Action(ClearSelectedConversation)
        clearSelectedConversation(ctx: StateContext<ChatStateModel>) {
            const state = ctx.getState()
            ctx.setState({...state, selectedConversation: null})
    }

    @Action(GetMessagesAction)
    getMessages(ctx: StateContext<ChatStateModel>, { chatId }: GetMessagesAction) {
        return this._cs.getMessages(chatId).pipe(
            tap((conversation: any) => {
                const state = ctx.getState()
                ctx.setState({...state, conversation})
            })
        )
    }
        
}