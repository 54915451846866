import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMedicine, IResultMedicine } from '@core/interfaces/medicinas.interface';
import { environment } from '@environments/environment';
import { Select } from '@ngxs/store';
import { FiltersState } from '@state/index.states';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MedicinesService {

  //@Select(FiltersState.getMedicinesFilters) filters$: Observable<any>;

  endpoint: string = 'medicines';

  filters: any = {
    medicine_id: '',
    brand: '',
    laboratory: '',
    type: '',
    page: '1',
    size: '10',
    sort: '',
  }

  constructor(
    private http: HttpClient
  ) { 
    //this.filters$.subscribe(filters => this.filters = filters) 
  }

  getMedicineType(id: number){
    this.endpoint = `medicines/treatment/${id}/types`;
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      )
  }

  getMedicineTreatmentType(){
    this.endpoint = 'treatments';
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      )
  }

  getMedicinePresentation(){
    this.endpoint = 'medicines/presentation';
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      )
  }

  getMedicines(params: any){
    this.endpoint = 'medicines';
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`, {
      // params: this.filters
      params
    }).pipe(
        //map(res => res["result"])
        map((res: IResultMedicine) => res)
    );
  }

  getMedicine(id: string){
    this.endpoint = 'medicines';
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`, {
      params: new HttpParams()
          .set('medicine_id', id)
    }).pipe(
        //map(res => res["result"])
        map((res: IResultMedicine) => res)
    );
  }

  getMedicineTypeOptions(treatmentId: number = 1){
    this.endpoint = `medicines/option/medication_type?treatment_id=${treatmentId}`;
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`).pipe(
      map(res => res)
    )
  }

  getMedicineOptions(id: number){
    this.endpoint = `medicines/option/${id}`;
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  getMedicinesModified(){
    this.endpoint = 'medicines';
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`, {
      params: new HttpParams()
      .set('size', '100')
    }).pipe(
        //map(res => res["result"])
        map((res: any) => {
            const result = res.result.reduce((acc, d) => {
            // const value = { value: d.id, label: d.brand };
            const value = { id: d.id, brand: d.brand };
            acc.push(value);
            return acc;
          }, []);
          
          return result;
        })
    );
  }

  postMedicine(medicina: IMedicine){
    this.endpoint = 'medicines';
    return this.http.post(`${environment.apiUrl}/${this.endpoint}`, medicina)
    .pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  putMedicine(medicina: IMedicine, id: string){
    this.endpoint = 'medicines';
    return this.http.put(`${environment.apiUrl}/${this.endpoint}/${id}`, medicina)
    .pipe(
      map((response: any) => {
        return response;
      })
    )
  }
}
