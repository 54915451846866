import { Injectable } from "@angular/core";
import { DashboardService } from "@dashboard/dashboard.service";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Observable, tap } from "rxjs";
import { LoadDashboardAction } from "./dashboard.actions";

export class DashboardStateModel {
    header: any
    pharmacies: any
}

@State<DashboardStateModel>({
    name: 'dashboard',
    defaults: {
        header: null,
        pharmacies: null
    }
})

@Injectable()
export class DashboardState {

    constructor(
        private _ds: DashboardService
    ){}

    @Selector()
    public static getDashboard({ header, pharmacies }: DashboardStateModel){
        const dashboard = {
            header,
            pharmacies
        }

        return dashboard;
    }

    @Action(LoadDashboardAction)
    loadDashboard({getState, setState}: StateContext<DashboardStateModel>): Observable<any>{
        return this._ds.getOrdenes().pipe(
            tap(({header, pharmacies}) => { 
                const state = getState()
                setState({...state, header, pharmacies })
                })
        )
    }
}