export class GetClientsAction {
    static readonly type = '[Clients] Get Clients';
    //constructor(public parametros: any) { }
}

export class GetHeadersClientsAction {
    static readonly type = '[Clients] Get Clients Header';
    //constructor(public payload: IHeader) { }
}

export class GetClientAction {
    static readonly type = '[Clients] Get Client';
    constructor(public id: number) { }
}
export class GetClientsDetailAction {
    static readonly type = '[Clients] Get Client Detail';
    constructor(public id: number) { }
}

export class SetClientsSelectedAction {
    static readonly type = '[Clients] Set Selected';
    constructor(public payload: any) { }
}

export class GetClientsSelectedAction {
    static readonly type = '[Clients] Get Selected';
    constructor(public id: number) { }
}


export class SetClientsSelectedDetailAction {
    static readonly type = '[Clients] Set Selected Detail';
    constructor(public payload: any) { }
}