import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
    SetClientsSelectedAction,
    GetClientsAction,
    GetClientsDetailAction,
    GetClientsSelectedAction,
    SetClientsSelectedDetailAction
} from './clients.actions';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IClientsHeader, IClient, IResultClients, IClientDetail } from '@interfaces/clients.interface';
import { ClientsService } from '@clients/clients.service';

export class ClientsStateModel {
  public header: IClientsHeader;
  public clients: IClient[];
  public selectedClient: IClient;
  public selectedClientDetail: IClientDetail;
}

@State<ClientsStateModel>({
  name: 'clients',
  defaults: {
    header: null,
    clients: [],
    selectedClient: null,
    selectedClientDetail: null
  },
})

@Injectable()
export class ClientsState {
  constructor(
    private readonly _clients: ClientsService,
    //private _snackbar: SnackbarService,
  ) {}

  @Selector()
  public static getHeader({ header }: ClientsStateModel): IClientsHeader {
      return header;
  }

  @Selector()
  public static getClientsList({ clients }: ClientsStateModel): IClient[] {
      return clients;
  }

  @Selector()
  public static getSelectedClient({ selectedClient }): IClient {
      return selectedClient;
  }

  @Selector()
  public static getSelectedClientDetail({ selectedClientDetail }): IClientDetail {
      return selectedClientDetail;
  }


  @Action(GetClientsAction)
  getClients({ getState, setState }: StateContext<ClientsStateModel>, { } : GetClientsAction ): Observable<IResultClients> {
      return this._clients.getClients().pipe(
        tap((respuesta: IResultClients) => { //
          const state = getState();
          if(respuesta.result.length > 0){
            setState({ ...state, header: respuesta.header, clients: respuesta.result  }); // header: respuesta.header, section: respuesta.section,
          }
        })
      );
  }

  @Action(GetClientsSelectedAction)
  getClient({ getState, setState }: StateContext<ClientsStateModel>, { id } : GetClientsSelectedAction ): Observable<any> {
      return this._clients.getClient(id).pipe(
        tap((respuesta: any) => {
          const state = getState();
          if(respuesta.length > 0){
            setState({ ...state, selectedClient: respuesta[0] }); // header: respuesta.header, section: respuesta.section,
          }
        })
      );
  }

  @Action(GetClientsDetailAction)
  getClientDetail({ getState, setState }: StateContext<ClientsStateModel>, { id } : GetClientsDetailAction ): Observable<any> {
      return this._clients.getClientDetail(id).pipe(
        tap((respuesta: any) => {
          const state = getState();
          setState({ ...state, selectedClientDetail: respuesta  }); // header: respuesta.header, section: respuesta.section,
        })
      );
  }

  @Action(SetClientsSelectedAction)
  setClientsSelected({ getState, setState }: StateContext<ClientsStateModel>,{ payload }: SetClientsSelectedAction) {
      const state = getState();
      setState({ ...state, selectedClient: payload })
  }



}
