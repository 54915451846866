export class GetConversationsActions {
    static readonly type = '[Chats] Get conversations'
    constructor(public page: number, public quantity: number, public status: string = 'ALL', public searchFilter: string = '') {}
}

export class SetSelectedConversation {
    static readonly type = '[Chats] Set selected conversation'
    constructor(public selectedConversation: any) { }
}

export class ClearSelectedConversation {
    static readonly type = '[Chats] Clear selected conversation'
    constructor() { }
}

export class GetMessagesAction {
    static readonly type = '[Chat] Get Messages';
    constructor(public chatId:string) { }
}